import React from "react"
// import { Link } from "gatsby"

const SentientPrime = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616581413/digitalavenues.com/static/SentientPrimeMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Sentient Prime Platform</h2>
                <p>The Sentient platform is hosted on AWS.  The platform leverages AWS services for auto-scaling and leverages S3 for file storage along with DynamoDB for data caching. The back-end services leverage robust .Net technologies to process data and store it into DB for further analysis.</p>
                <p>The Sentient platform is designed to auto-scale according to the business’ needs.  Currently, the platform processes on average 300 requests per minute, ranging from 100 to 600 RPM throughout the day.  Infrastructure can be scaled in near real-time to meet higher demands. The Sentient Survey can be integrated easily to any third-party survey and redirected to Sentient along with values to carry over and be used later for statistical data analysis.</p>

                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Hosting</div>
                            <div class="contRight">
                                <p>AWS (EC2, S3, SQS, RDS, CloudFront)</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">User Interface</div>
                            <div class="contRight">
                                <p>Angular, React, BackboneJS, RequireJS, D3,js</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Data Cache</div>
                            <div class="contRight">
                                <p>DynamoDB</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Transactional Database</div>
                            <div class="contRight">
                                <p>Aurora MySQL</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Search</div>
                            <div class="contRight">
                                <p>Google Image Search and Suggest APIs</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>
                <p>Our team is tightly aligned with the evolution of Sentient’s technology and continuously stays abreast of current trends by reading industry publications, monitoring the industry’s environment and competitor blogs, as well as message boards. To help Sentient stay ahead of business demands, our team provided the following solutions:</p>
                <ul className="listStyle2">
                    <li>Survey builder</li>
                    <li>Survey execution and data collection</li>
                    <li>Image search &amp; suggestion</li>
                    <li>Real-time visualization of the survey metrics</li>
                </ul>
                <p></p>
                <p>A Survey is built to capture human behavior and response. w.r.t. Speed and Accuracy. Speed denotes how quickly you are able to identify the emotional attribute and Accuracy measures whether you have selected the correct emotional attribute.  A Survey can be executed from various devices, including desktop, tablet, and mobile, irrespective of the browser. The Survey runtime captures the responses and stores them asynchronously as JSON files on S3. This helps dig the data for final results within the control panel. Surveys are executed across the globe, based on certain parameters. The survey updates the messaging queue, and from the control panel, the user can observe the count in real-time.</p>
                <div className="imgBlk mt40">
                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616582891/digitalavenues.com/static/sentientPrimePlatform.png" alt="" />
                </div>
            </div>
        </section>
    </div>
)
export default SentientPrime

