import React from "react"
// import { Link } from "gatsby"

const Cleversort = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <video playsinline autoplay="true" muted loop>
                <source src="https://firebasestorage.googleapis.com/v0/b/cleversort-f9c9f.appspot.com/o/assets%2Fvideos%2Fhero_v6.mp4-vp9.webm?alt=media" type="video/webm" />
                <source src="https://firebasestorage.googleapis.com/v0/b/cleversort-f9c9f.appspot.com/o/assets%2Fvideos%2Fhero_v6.mp4-hevc.mp4?alt=media" type="video/mp4" />
                <source src="https://firebasestorage.googleapis.com/v0/b/cleversort-f9c9f.appspot.com/o/assets%2Fvideos%2Fhero_v6.mp4-h264.m4v?alt=media" type="video/mp4" />
                <p>This browser does not support the video element.</p>
            </video>
                        {/* <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616068261/digitalavenues.com/static/CleversortCaseStudy.png" alt="" /> */}
        </div>
                    <section className="projDtlsSecContainer projDtlsContainer innerSection">
                        <div className="wrapper">
                            <h2>Our Involvement</h2>
                            <p>Cleversort’s main objective was to simplify the task creation and management process. The challenge was to streamline the complex management processes that are often found in tradtional workflow tools. The design and development efforts included a UI design system and a library of custom illustrations. The vibrant brand colours and whimsical characters were designed to showcase the brand’s playful personality throughout the web app, mobile app, and marketing website.</p>
                            <div>
                                <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616068261/digitalavenues.com/static/CleversortDesignsImg.png" alt="" />
                            </div>
                            <div className="innovationBlk projDtlsInvolvement">
                                <ul>
                                    <li>
                                        <div className="contLeft prodDtlsContLeft">Discovery</div>
                                        <div className="contRight">
                                            <p>User personas were created to define discovery questions such as:</p>
                                            <ul className="listStyle2">
                                                <li>Is there data that backs up the primary pain point? Where from?</li>
                                                <li>What insights do we have regarding the product’s use case?</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contLeft prodDtlsContLeft">Define</div>
                                        <div className="contRight">
                                            <p>Using the personas, oru team dug deeper into the pain points:</p>
                                            <ul className="listStyle2">
                                                <li><strong>Insight:</strong> Many teams conduct the same tasks/processes throughout their workflows</li>
                                                <li><strong>Need:</strong> Users need an easy and centralised location to view project status and identify roadblocks</li>
                                                <li><strong>POV statement:</strong> John is managing a client website redesign and needs a way to monitor progress for each web page and their corresponding components (Copywriting, Images, UI design, Development, Testing, etc.)</li>
                                                <li><strong>HMW:</strong> How might we help John visualise project status across multiple roles and tasks at a glance?</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contLeft prodDtlsContLeft">Ideation</div>
                                        <div className="contRight">
                                            <p>Using what we know, two types of deliverables were created:</p>
                                            <ul className="listStyle2">
                                                <li>Low/mid-fidelity wireframes</li>
                                                <li>Key screens and step-by-step of main flow(s)</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contLeft prodDtlsContLeft">Validate</div>
                                        <div className="contRight">
                                            <ul className="listStyle2">
                                                <li>User testing to identify pain points with current prototype</li>
                                                <li>Potential solutions  and consideration for future iterations and product roadmap</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contLeft prodDtlsContLeft">UI Design</div>
                                        <div className="contRight">
                                            <p>One of the primary aspects of the UI was its simplicity. We needed to create an interface that was both unique and user-friendly, with strategic use of the brand’s colours to establish hierarchy throughout the application.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contLeft prodDtlsContLeft">Illustrations</div>
                                        <div className="contRight">
                                            <p>A library of illustrations was created exclusively for Cleversort. Illustrated Cleversort characters were integrated into parts of the app and website to reinforce its cheerful branding while providing context to the screen.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contLeft prodDtlsContLeft">Cleversort Website</div>
                                        <div className="contRight">
                                            <p>For the marketing site, we opted for a similar design but introduced an increased combination of their vibrant brand colours to show off their brand’s playful personality. </p>

                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </section>
    </div>
)

export default Cleversort
