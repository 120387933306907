import React from "react"
// import { Link } from "gatsby"

const Evzdrop = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616068524/digitalavenues.com/static/evzdropCaseStudyHeaderImg.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>How it Works</h2>
                <p>With Evzdrop, a celebrity user can set up and publish a live session, which is released on their individual web widget. Fans can join and listen to the live session on this web widget. The caster can share pictures and videos of their live session as well as live videos or audio streams to create a real-life experience with their audience. With Evzdrop, users can: </p>
                <div className="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Create a Live Session</div>
                            <div className="contRight">
                                <ul className="listStyle2">
                                    <li>The caster setting up a live session by selecting tracks either from Apple music or Audiomack</li>
                                    <li>The caster can upload a cover photo and provide a title for the live session</li>
                                    <li>The caster can manage his/ her own songs queue</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Join a Live Session</div>
                            <div className="contRight">
                                <ul className="listStyle2">
                                    <li>Join a live session simply by providing an email address</li>
                                    <li>Listen to the tracks with caster seamlessly in real-time</li>
                                    <li>The listener can interact by liking a track that is currently playing</li>
                                    <li>The listeners can view pictures or videos shared by the caster on the live session</li>
                                    <li>They can also preview played tracks list</li>
                                    <li>Group chat with the caster</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Stream Video or Audio Live</div>
                            <div className="contRight">
                                <ul className="listStyle2">
                                    <li>The caster can connect to a live video or audio streaming and broadcast simultaneously with the live session</li>
                                    <li>The caster can share his/ her experience through live broadcasting.</li>
                                    <li>The ultra-low latency streaming reduces the lag time maximum up to 3 seconds</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Group Chat</div>
                            <div className="contRight">
                                <p>The live session is an integrated messaging system that enables communication between the caster and their listeners. The caster and their listeners can also interact by liking tracks.</p>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement </h2>

                <p>Our team was involved with the following areas of system development:</p>
                <ul className="listStyle2">
                    <li>Requirements analysis and technical design</li>
                    <li>User experience and front-end design and development</li>
                    <li>Back-end and middle tier design and development</li>
                    <li>Back-end architecture design</li>
                </ul>
                <br />
                <p>Evzdrop is constantly evolving with new features, feature improvements, and capability improvements. Our team of developers and testers therefore work closely with the Evdrop’s technical team in an Agile environment to quickly turnaround new requests with quality. Our post-development involvement includes routine maintenance and upgrades of the infrastructure, refactoring and optimization of the existing codebase, and providing suggestions to improve the product and features. </p>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Evzdrop’s Architecture</h2>
                <p>Evzdrop runs on a Google Cloud Architecture Platform in an auto-scaling load balanced infrastructure.</p>
                <div><strong>Backend Environment</strong></div>
                    <ul className="listStyle2">
                    <li><strong>Google Cloud Firestore</strong></li>
                    <li><strong>Google Firebase Real Time Db</strong></li>
                    <li><strong>Google Cloud Function</strong></li>
                    <li><strong>Firebase cloud messaging</strong> is used as a module between the server and the devices that receive the push notifications.</li>
                    <li><strong>Firebase email &amp; SMS authentication service</strong> is used for all email &amp; SMS communication from the app.</li>
                    <li>Evzdrop uses <strong>Cloudinary</strong> for image &amp; video manipulation.</li>
                    <ul className="listStyle2 ml30">
                        <li>Cloudinary is used as a secure cloud storage for images &amp; videos.</li>
                        <li>Cloudinary image processing is used for image optimization without affecting the image quality.</li>
                        <li>Cloudinary video transformations are used for resizing &amp; cropping videos and on-the-fly video encoding and delivery</li>
                    </ul>
                    <li>Evzdrop uses <strong>Agora Video and Voice SDK</strong> for live streaming while the user is on a live session.</li>
                    <ul className="listStyle2 ml30">
                        <li>Wowza cloud streaming is used for live streaming with ultra-low latency.</li>
                    </ul>
                    <li>Evzdrop uses <strong>Algolia</strong> for search users</li>
                    <ul className="listStyle2 ml30">
                        <li>Algolia content search algorithm is used for search users within the app.</li>
                    </ul>
                </ul>
                <br />
                <p><strong>Scalability</strong></p>
                <p>Google Cloud Firestore is built for an exabyte scale using the same infrastructure that powers Hotstar with auto threading.</p>

                <p><strong>Security</strong></p>
                <p>Cloud Storage integrates with Firebase Authentication to provide simple and intuitive authentication. It can use the security model to allow access, based on filename, size, content type, and other metadata.</p>
            </div>
        </section>

    </div>
)
export default Evzdrop

