import React from "react"
import { Link } from "gatsby"
import ProjectSlider from "./projectSlider";
import Cleversort from "./cleversort";
import InskinMedia from "./inskin-media";
import Evzdrop from "./evzdrop";
import Adtheorent from "./adtheorent";
import InSkinMediaAdPlatform from "./inskin-media-ad-platform";
import Aptra from "./aptra";
import SentientPrime from "./sentient-prime";
import SentientExpression from "./sentient-expression";
import Brandverge from "./brandverge";
import InsideSeat from "./insideseat";
import ITrust from "./itrust";
import SmlApp from "./sml-app";


const MainContent = ({pageSlug, projectId, sliderImages}) => {

    function renderContent(){
        //alert(projectId)
        switch (projectId) {
            case 'Projects_6': // Cleversort
                return <Cleversort/>
            case 'Projects_7': // InSkin Media
                return <InSkinMediaAdPlatform/>
            case 'Projects_8': // Evzdrop
                return <Evzdrop/>
            case 'Projects_10': // Adtheorent
                return <Adtheorent/>
            case 'Projects_14': // Sentient Prime
                return <SentientPrime/>
            case 'Projects_15': // Brandverge
                return <Brandverge/>          
            case 'Projects_17': // Aptra
                return <Aptra/>            
            case 'Projects_18': // Sentient Expression
                return <SentientExpression/>
            case 'Projects_19': // InSkin Media Ad Platform
                return <InskinMedia/>            
            case 'Projects_22': // InsideSeat
                return <InsideSeat/>
            case 'Projects_23': // iTrust
                return <ITrust/>
            case 'Projects_24': // SmlApp
                return <SmlApp/>
                
            default :
                return <ProjectSlider sliderImages={sliderImages}/>
        }
    }

    return(
        <React.Fragment>

            {renderContent()}

            <section className="viewMoreCaseStudiesContainer innerSection">
                <div className="wrapper">
                    <div class="goDetail">
                        <Link class="readMore" to={'/our-work'}>View More Case Studies</Link>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default MainContent
