import React from "react"
// import { Link } from "gatsby"

const ITrust = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616646477/digitalavenues.com/static/iTrustMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>How it Works</h2>
                <p>On a secure platform, family and friends can contribute to a beneficiary under one account. Anyone can create an account: parents, grandparents, godparents, aunts and uncles. With an iTrust account: </p>
                <ul class="listStyle2">
                    <li>Guardians can add up to 10 children or dependents under one account</li>
                    <li>Family and Friends can send contributions to Guardians</li>
                    <li>Guardians can perform online identity check and document verification</li>
                    <li>Guardians can receive secure payments through trusted bank accounts</li>
                    <li>Guardians can invite family and friends to contribute</li>
                    <li>Guardians can accept or reject contributions</li>
                    <li>For multiple beneficiaries, the contribution is divided based on predefined rules</li>
                    <li>Guardians can receive customisable reports</li>
                </ul></div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>
                <p>Our team was responsible for building out the architecture and platform. With a highly scalable and robust backbone, the iTrust platform can handle 3000 requests per minute with a minimum infrastructure of 2 servers behind a load-balancer. The architecture is flexible enough to add or remove servers, depending on the load. The web application is fully responsive across mobile, tablet and desktop. The frontend follows .net MVC architecture, while WEB API will communicate to AWS Aurora PGSQL  to transact information between frontend and web-API.</p>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Hosting</div>
                            <div class="contRight">
                                <p>AWS (EC2, S3, RDS, ELB)</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Front End</div>
                            <div class="contRight">
                                <p>ASP.NET MVC4.5 and Web API</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Transactional Database</div>
                            <div class="contRight">
                                <p>Aurora PGSQL</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
)
export default ITrust

