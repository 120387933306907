import React from "react"
// import { Link } from "gatsby"

const InskinMedia = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616068584/digitalavenues.com/static/inSkinMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>The InSkin Ad Platform</h2>
                <p>The InSkin Ad Platform is the infrastructure which supports the business in managing advertising campaigns and delivering them to appropriate target audiences around the world.</p>
                <div className="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Campaign Management</div>
                            <div className="contRight">
                                <p>The platform provides an intuitive interface to traffickers and campaign managers to manage the various ad campaigns and insertion orders from a central interface which also provides actionable information to make quick decisions.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Planning and Forecasting</div>
                            <div className="contRight">
                                <p>The Planning and Forecasting tools enable the business to accurately manage and allocate campaign targets to Publishers depending on historical traffic trends, availability of inventory, and constraints imposed by Publishers or Advertisers.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Scheduling Algorithm</div>
                            <div className="contRight">
                                <p>The Intelligent Scheduling Algorithm allocates impressions to various campaigns across hundreds of publishers depending on various parameters like flight delivery rates, available inventory, campaign timelines, and advertiser’s demands.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Comprehensive Targeting</div>
                            <div className="contRight">
                                <p>To serve ads, the Ad selection algorithm has to process various targeting parameters like Geolocation, Keywords, ISP, Browser, OS, Screen Resolution, and Network Connection Speed. It has to also honor frequency capping and priority targets set at various levels.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Ad Tracking &amp; Data Logging</div>
                            <div className="contRight">
                                <p>Various metrics like “ads served”, “ads viewed”, “clicks” and custom events as defined on the creatives are tracked in real-time and sent to the backend where it is stored in a middle-tier cache and frequently cleared back into the enterprise data warehouse. This gives the ability to the business to get actionable insights and take tactical and strategic decisions.</p>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement </h2>

                <p>Our team has been responsible for building the InSkin Media Platform since its inception in the following areas of system development:</p>
                <ul className="listStyle2">
                    <li>Requirements analysis and technical design</li>
                    <li>User experience and front-end design and development</li>
                    <li>Back-end and middle tier design and development</li>
                    <li>Hosting architecture design</li>
                    <li>Hosting and deployment infrastructure setup</li>
                </ul>
                <p></p>
                <p>The platform is constantly evolving and our team of developers and testers are engaged closely with InSkin’s technical team in an agile environment to maintain upgrades of the infrastructure, refactoring and optimizing system capabilities.</p>

            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <div className="dedicatedBlk">
                    <ul>
                        <li>
                            <h3>Platform Features</h3>
                            <ul className="listStyle2">
                                <li>Campaign Planning</li>
                                <li>Inventory Forecast</li>
                                <li>Campaign Management, Targeting and Trafficking tools</li>
                                <li>Creative Management</li>
                                <li>Ad Scheduling &amp; Optimisation</li>
                                <li>Ad Serving</li>
                                <li>Ad Tracking</li>
                                <li>Telemetry</li>
                                <li>On-Demand Reporting</li>
                                <li>Scheduled Reporting</li>
                                <li>Data Logging</li>
                                <li>User Access Control</li>
                                <li>Management of Publishers, Advertisers, Media Agency</li>
                            </ul>
                        </li>
                        <li>
                            <h3>Tech Stack</h3>
                            <ul className="listStyle2">
                                <li>Microsoft .NET</li>
                                <li>.Net Core</li>
                                <li>ASP.NET MVC</li>
                                <li>Telerik Kendo UI</li>
                                <li>SQL Server</li>
                                <li>SSIS</li>
                                <li>GCP Memory Store - Redis</li>
                                <li>GCP Stack Driver</li>
                                <li>GCP Pub/Sub</li>
                                <li>Cloud Storage</li>
                                <li>Cloud Filestore</li>
                                <li>Google Big Query</li>
                                <li>NetAcuity by Digital Elements</li>
                                <li>ElasticSearch</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>InSkin’s System Capabilities</h2>
                <p>The Ad Platform runs on a distributed micro services architecture (Web farm+Web Garden) with the capability to scale horizontally and vertically without impacting performance. The resources are distributed in multiple availability zones to mitigate the risk of physical data center failures. The platform is hosted on the Google Cloud Platform in an auto-scaling load balanced infrastructure. To date, the platform has served over <strong>8 billion ads with an uptime of 99.5%.</strong></p>
                <div className="quickDesc texCenter">
                    <ul>
                        <li>
                            <div className="secCount">60<span>K</span></div>
                            <div>Hits<br /> Per Minute</div>
                            <p>The ad server handles between <strong>24K to 60K calls</strong> per minute</p>
                        </li>
                        <li>
                            <div className="secCount">11<span>M</span></div>
                            <div>Ad Requests<br />Per Day</div>
                            <p>The ad server receives about <strong>11 million ad requests</strong> per day from different publishers</p>
                        </li>
                        <li>
                            <div className="secCount">2.5<span>M</span></div>
                            <div>Ads  Served<br />per day</div>
                            <p>The ad server serves about <strong>2.5 million ads</strong> per day after matching and filtering the ad requests</p>
                        </li>
                        <li>
                            <div className="secCount"><span>&lt;</span>120</div>
                            <div>Response<br />Time</div>
                            <p>The average response time for an ad request to return a match is <strong>less than 120 milliseconds</strong></p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

    </div>
)

export default InskinMedia
