import React from "react"
// import { Link } from "gatsby"

const InsideSeat = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616739452/digitalavenues.com/static/insideSeatCaseStudy.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>How it Works</h2>
                <p>With InsideSeat, event creators can track and communicate with invited users using the message board which includes the ability to post pictures and videos within the event. Users may opt-in to get in-app or SMS notifications for any updates on an event.</p>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Create an Event</div>
                            <div class="contRight">
                                <p>To create an event, one guest invite is required with the ability to add the following details:</p>
                                <ul class="listStyle2">
                                    <li>Option to add an event heading or title</li>
                                    <li>Option to add location, date, and time (location is integrated with Google map API to find the event venue)</li>
                                    <li>Option to select and de-select more contacts from the user’s address book</li>
                                    <li>Option to add pictures, videos or third party links</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Create a Group</div>
                            <div class="contRight">
                                <p>Users can create a group with the number of invitees for an event. There are no limitations on the number of members for a group, nor the number of groups created. </p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Event Response</div>
                            <div class="contRight">
                                <ul class="listStyle2">
                                    <li><strong>Via App</strong> — InsideSeat app users can receive push notifications for event invitations; users can respond and communicate with other invited members in-app.</li>
                                    <li><strong>Via SMS</strong> — Non-app users with only one active invitation will receive a text message (SMS) originated from a short code. Users can respond with 1 for attending or 0 for not attending to an event. If a non-app user has more than 1 active invitation, they will be required to download the app to respond to the events.</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>
                <p>Our team was involved with the following areas:</p>
                <ul class="listStyle2 mb16">
                    <li>Requirements analysis and technical design</li>
                    <li>User experience and front-end design and development</li>
                    <li>Back-end and middle tier design and development</li>
                    <li>Back-end architecture design</li>
                </ul>
                <p>InsideSeat is an iOS application that supports version iOS 11 and above. The app runs on a Google Cloud Architecture Platform in an auto-scaling load balanced infrastructure. Our post-development involvement includes routine maintenance and upgrades of the infrastructure, refactoring and optimization of the existing codebase, and providing suggestions to improve the product.</p>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <ul class="brandVergeSysCap">
                    <li>
                        <h3>Back-end Environments</h3>
                        <ul class="listStyle2">
                            <li>Google Firebase Real-Time Db</li>
                            <li>Google Cloud Function</li>
                            <li>Firebase cloud messaging is used as a module between the server and the devices that receive the push notifications</li>
                            <li>Twillio service is used for all SMS communication from the app</li>
                        </ul>
                    </li>
                    <li>
                        <h3>Auto-scaling Load Balanced Infrastructure</h3>
                        <ul class="listStyle2">
                            <li>Google Cloud Architecture is built for an exabyte scale using the same infrastructure that powers Hotstar with auto threading</li>
                            <li>Cloud Storage integrates with Firebase Authentication to provide simple and intuitive authentication. It can use the security model to allow access, based on filename, size, content type, and other metadata</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </section>

    </div>
)
export default InsideSeat

