import React from "react"
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000
};

const ProjectSlider = ({ sliderImages }) => {
    return (
        <React.Fragment>
            <section>
                <div className="wrapper">
                    {sliderImages && sliderImages.length > 0 &&
                        <div className="sliderWrap">

                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false} // should stop playing on user interaction
                                interval={6000}
                            >
                                {
                                    sliderImages.map((_image, index) => {
                                        return (
                                            <div data-src={_image.url} />
                                        )
                                    })
                                }
                            </AutoplaySlider>
                        </div>
                    }
                </div>
            </section>
        </React.Fragment>
    )
}

export default ProjectSlider