import React from "react"
// import { Link } from "gatsby"

const Aptra = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616578716/digitalavenues.com/static/aptraMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>APTRA’s Website</h2>
                <p>APTRA’s website had several challenges and pain points:</p>
                <ul className="listStyle2">
                    <li>The navigation was overwhelming and unclear</li>
                    <li>There was a lack of information hierarchy and structure</li>
                    <li>The value proposition and members’ benefits were unclear</li>
                    <li>Most of the processes required more clicks than necessary, creating user friction</li>
                </ul>
                <p className="mt40">For the site re-design, our goal was to:</p>
                <ul className="listStyle2">
                    <li>Streamline the user experience by restructuring information architecture</li>
                    <li>Modernize the appearance and UI</li>
                    <li>Create a searchable platform for members to access various reports and resources</li>
                </ul>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement </h2>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">User Personas</div>
                            <div class="contRight">
                                <p>The re-design consisted of two main features: a public-facing informational portal and an exclusive library of resources for paid members. Our design process began with user personas in order to map out the user journeys of paid members. We discovered that:</p>
                                <ul className="listStyle2">
                                    <li>As an APTRA member, I want to find and connect with key people from other member organizations</li>
                                    <li>As an APTRA member, I want to find information around industry news and research so that I can better understand what is happening in the travel retail industry</li>
                                    <li>As a non-member, I want to understand what APTRA has to offer and explore if membership is right for me </li>
                                </ul>
                                <div className="imgBlk">
                                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616579164/digitalavenues.com/static/hikariImg.png" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Information Architecture</div>
                            <div class="contRight">
                                <p>Based on the user journeys and needs, we identified the key features of the site:</p>
                                <ul className="listStyle2">
                                    <li>Public-facing informational pages about the organization</li>
                                    <li>Public-facing membership application</li>
                                    <li>Industry-related news and events</li>
                                    <li>Exclusive members’ directory</li>
                                    <li>Exclusive database of APTRA-curated research reports</li>
                                </ul>
                                <div className="imgBlk imgGap">
                                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616579624/digitalavenues.com/static/infographicInfoArchi.png" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Ideation &amp; Wireframing </div>
                            <div class="contRight">
                                <p>Using the key findings, several mockups and wireframes were created: </p>
                                <div className="imgBlk">
                                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616579838/digitalavenues.com/static/aptra_sIdeationAndWireframing.png" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">UI Design</div>
                            <div class="contRight">
                                <p>For the UI, we retained APTRA’s original brand palette of red and grayscale shades, but opted for a more modern look with the layout and component design. We also re-kerned the typeface for a quick but impactful refresh.</p>
                                <div className="imgBlk">
                                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616579964/digitalavenues.com/static/styleguide.png" alt="" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>APTRA’s New Site Design</h2>
                <div className="imgBlk">
                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616580565/digitalavenues.com/static/aptra_sNewDesign.png" alt="" />
                </div>
            </div>
        </section>

    </div>
)

export default Aptra
