import React from "react"
// import { Link } from "gatsby"

const Adtheorent = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616064583/digitalavenues.com/static/adTheorentMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>AdTheorent’s Architecture</h2>
                <p>AdTheorent is built on a loosely coupled, service oriented architecture. It also exposes APIs for 3rd party systems to consume.</p>
                <div className="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Hosting</div>
                            <div className="contRight">
                                <p>AWS (EC2, S3, Load Balancers, SNS, SQS)</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">User Interface</div>
                            <div className="contRight">
                                <p>Angular, Bootstrap, HighCharts</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Business Logic Layer</div>
                            <div className="contRight">
                                <p>C#</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Transactional Databases</div>
                            <div className="contRight">
                                <p>SQL Server, Cassandra, PostgreSQL</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Data Warehouse</div>
                            <div className="contRight">
                                <p>Redshift</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Search</div>
                            <div className="contRight">
                                <p>ElasticSearch</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">Background Services</div>
                            <div className="contRight">
                                <p>Windows service, Windows Task Scheduler, SSIS</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft prodDtlsContLeft">API</div>
                            <div className="contRight">
                                <p>ASP.NET Web API / OAuth2.0</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>
                <p>Our team is tightly aligned with the evolution of AdTheorent’s technology and continuously stays abreast of current trends by reading industry publications, monitoring the industry’s environment and competitor blogs, as well as message boards. To help AdTheorent stay ahead of business demands, our team provided the following solutions:</p>
                <ul className="listStyle2">
                    <li>Campaign Setup</li>
                    <li>Strategy Setup</li>
                    <li>Targeting</li>
                    <ul className="listStyle2 ml30">
                        <li>Point of Interest Targeting</li>
                        <li>Geography Targeting</li>
                        <li>Predictive Targeting</li>
                        <li>Ad-Exchange Targeting</li>
                        <li>Device/OS/Browser/Carrier Targeting</li>
                        <li>Audience Segments</li>
                        <li>Day Parting</li>
                        <li>Weather and Temperature Target</li>
                    </ul>
                    <li>Real-time bidding monitoring</li>
                    <li>Tableau reporting integration</li>
                    <li>Managed Notifications</li>
                    <li>3rd party Vendor Data Extraction tool</li>
                    <li>Transfer Strategic Data to RTB Engine</li>
                </ul>
                </div>
        </section>
        </div>
)
export default Adtheorent

