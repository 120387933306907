import React from "react"
// import { Link } from "gatsby"

const SentientExpression = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616583825/digitalavenues.com/static/sentientExpressionMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Sentient Expression Platform</h2>
                <p>The Sentient Platform can precisely predict consumer behaviors towards an ad by measuring emotions. With a highly scalable and robust backbone, a survey can handle 100-200 requests per minute with a minimum infrastructure of 2 servers behind a load-balancer. The architecture is flexible enough to add more servers depending upon the load and remove it as well.</p>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Hosting</div>
                            <div class="contRight">
                                <p>AWS (EC2, RDS, S3, CloudFront, DynamoDB, SQS)</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">User Interface</div>
                            <div class="contRight">
                                <p>Angular, Flask</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Face Detection</div>
                            <div class="contRight">
                                <p>CLMTracker</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Transactional Database</div>
                            <div class="contRight">
                                <p>Aurora MySQL</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Inference Models</div>
                            <div class="contRight">
                                <p><strong>DLAMI (Deep Learning Amazon Machine Image)</strong> with Anaconda virtual environment; <strong>Apache MXNet deep learning framework</strong></p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Image Processing</div>
                            <div class="contRight">
                                <p>FFMpeg</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Caching</div>
                            <div class="contRight">
                                <p>Redis</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Computer Vision</div>
                            <div class="contRight">
                                <p>OpenCV</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>
                <p>Our team is tightly aligned with the evolution of Sentient’s technology and continuously stays abreast of current trends by reading industry publications, monitoring the industry’s environment and competitor blogs, as well as message boards. To help Sentient stay ahead of business demands, our team provided the following solutions:</p>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Face Detection &amp; Tracking</div>
                            <div class="contRight">
                                <p>The program employs a set of sequential steps to detect a face for the first time in the underlying video stream.</p>
                                <ul class="listStyle2 mb16">
                                    <li><strong>Viola-Jones:</strong> The objective of this step is to detect a bounding box/window around the face (if any exists at all) in the image data.</li>
                                    <li><strong>MOSSE Correlation Filter:</strong> This step involves the application of the MOSSE correlation filter to track (one time) the two eyes and the nose (facial components) on the detected face.</li>
                                    <li><strong>Procrustes Analysis:</strong> This step involves performing the Procrustes Analysis to determine the scaling, rotation, translation along the X-axis and translation along the Y-axis that need to be removed from the detected face in order to roughly align it with the mean face.</li>
                                    <li><strong>Initial Guess of Feature Points:</strong> This step involves guessing the initial X and Y-coordinates of the facial feature points. The clmtracker program tracks a face by tracking 71 facial feature points.</li>
                                    <li><strong>Prep the Drawing Canvas:</strong> This step involves preparing the canvas to draw the frame content (the image data) to work on.</li>
                                    <li><strong>SVM &amp; Response Image for Each Patch:</strong> In this step, the program extracts a patch window of 11X11 size and converts pixel data of this patch image into grayscale. For each of the patch images, SVM is performed to obtain the corresponding response image. Response images are stored into an array henceforth called as responses.</li>
                                    <li><strong>Optimization for Best Feature Point Positions:</strong> In this step, the response images obtained from the previous step are optimized to get the best positions for the feature points.</li>
                                </ul>
                                <p>After successfully detecting a face and having the current positions of all the 71 feature points of that face, the parameters or weights — with each iteration, on the same frame or continuing with a new frame —  is constantly updated and applied to obtain the new positions of those feature points to keep track of the face in the underlying video. Once the tracking process is completed, the recorded webcam video and tracking data get uploaded to AWS S3 service.</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Inference on Tracking Data</div>
                            <div class="contRight">
                                <p>Machine Learning models consume and process the image frames to extract the inferred emotion from each frame. The complete data set is then aggregated based on the timestamp and saved to the database. These database tables are then used to generate tabular results in the control panel.</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Visualization of Results</div>
                            <div class="contRight">
                                <p>Charts depicting the various emotions are drawn on top of the base video and changes as per the timeline of the video.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="imgBlk mt40">
                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616585754/digitalavenues.com/static/sentientExpressionPlatform.png" alt="" />
                </div>
            </div>
        </section>
    </div>
)
export default SentientExpression

