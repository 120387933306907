import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MainContent from "../../components/projects/mainContent"

const siteURL = `${process.env.SITE_URL}`;

const Project = ({ data, pageContext }) => {

    const projectData = data.strapiProjects;
    const metaImage = siteURL+projectData.cover_image_large.publicURL;

    function makeServiceString(serviceArray){
        let serviceString = '';
        if(serviceArray && serviceArray != null && serviceArray != ''){
            let serviceValueArray = [];
            serviceArray.map((service) =>
                serviceValueArray.push(service.name)
            );
            serviceString = serviceValueArray.join(', ');
        }
        return serviceString;
    }
    
    function makeCoreTechnologyString(coreTechnologyArray){
        let coreTechnologyString = '';
        if(coreTechnologyArray && coreTechnologyArray != null && coreTechnologyArray != ''){
            let coreTechnologyValueArray = [];
            coreTechnologyArray.map((coreTechnology) =>
                coreTechnologyValueArray.push(coreTechnology.name)
            );
            coreTechnologyString = coreTechnologyValueArray.join(', ');
        }
        return coreTechnologyString;
    }

    return (
        <Layout>
            <SEO title={projectData.title+ ' | Digital Avenues'} image={metaImage} description={projectData.short_description} />
            <div className="contentWrapper">
                <section className="projDtlsContainer innerSection dtlsTopSection">
                    <div className="wrapper">
                        <div className="projDtlsTopContWrap">
                            <div className="prodDtlsTopLeft">
                                <h2>{projectData.title}</h2>
                            </div>
                            <div className="vLine"></div>
                            <div className="prodDtlsTopRight">
                                <div className="descCont">
                                    <p>{projectData.short_description}</p>
                                    <div className="techBlk">
                                        <p><strong>Services: </strong>{ makeServiceString(projectData.services) }</p>
                                        <p><strong>Core Technologies: </strong>{ makeCoreTechnologyString(projectData.core_tech) }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <MainContent pageSlug={projectData.slug} projectId={projectData.id} sliderImages={projectData.slider_images} />
            </div>
        </Layout>
    )

}

export const query = graphql`
  query($slug: String){
    strapiProjects(slug: {eq: $slug}) {
      id
      title
      slug
      short_description
      services {
        name
      }
      core_tech {
        name
      }
      cover_image_large {
        publicURL
      }
      slider_images {
        url
      }
    }
  }
`

export default Project
