import React from "react"
// import { Link } from "gatsby"

const SmlApp = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616647762/digitalavenues.com/static/smlAppMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>The SML App Platform</h2>
                <p>With the SML App, users can navigate between different categories and filters to view data per occupation group and sub-group.  The platform consists of two primary screen layouts, a world map view and a tree map view with the content dynamically displaying based on user input.  With the SML App, users can:</p>
                <ul class="listStyle2">
                    <li>See clear, color-coded overview of global data</li>
                    <li>Search for and view specific country data</li>
                    <li>Filter data by types of machine learning technology</li>
                    <li>Download country-specific data</li>
                    <li>View data per occupation group and sub-group</li>
                    <li>View information about the project</li>
                </ul>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>
                <p>Our team was responsible for the design and development of the web app from its inception. The biggest challenge was the data visualization <br />
                — large amounts of research data needed to be clearly represented in multiple segments and formats while allowing the user to intuitively navigate between different categories and levels of information. </p>
                <div className="imgBlk">
                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616652793/digitalavenues.com/static/smlAppImg1.png" alt="" />
                </div>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Wireframing</div>
                            <div class="contRight imgBlk">
                                <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616652654/digitalavenues.com/static/smlAppWireframing.png" alt="" />
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">UI Design</div>
                            <div class="contRight imgBlk">
                                <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616652455/digitalavenues.com/static/smlAppUIDesign.png" alt="" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
)
export default SmlApp

