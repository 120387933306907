import React from "react"
// import { Link } from "gatsby"

const Brandverge = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616586060/digitalavenues.com/static/brandVergeMockup.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>The BrandVerge Platform</h2>
                <p>The BrandVerge Platform runs on a distributed architecture (Web farm+Web Garden) with the capability to scale horizontally and vertically without impacting performance. The resources are distributed in multiple availability zones to mitigate the risk of physical data center failures. The platform is hosted on the AWS Cloud Platform in an auto-scaling load-balanced infrastructure.</p>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Hosting</div>
                            <div class="contRight">
                                <p>AWS (EC2, S3, CloudFront, Cloudwatch)</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">User Interface</div>
                            <div class="contRight">
                                <p>Angular, Angular Material</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Back-end Services</div>
                            <div class="contRight">
                                <p>Node.js, Express</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Database</div>
                            <div class="contRight">
                                <p>PostgreSQL</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Analytics</div>
                            <div class="contRight">
                                <p>Google Analytics, Segment</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Transactional Emails</div>
                            <div class="contRight">
                                <p>Sendgrid</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Feature Management</div>
                            <div class="contRight">
                                <p>LaunchDarkly</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Real-time Chat</div>
                            <div class="contRight">
                                <p>Firebase Realtime Database</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>
                <p>Our team built BrandVerge’s platform from the ground up and was involved in the following areas of system development:</p>
                <ul class="listStyle2 mb16">
                    <li>Requirements analysis and technical design</li>
                    <li>User experience and front-end design and development</li>
                    <li>Back-end and middle tier design and development </li>
                    <li>The hosting architecture design</li>
                    <li>The hosting and deployment infrastructure setup</li>
                </ul>
                <p>We’re closely involved with the team at BrandVerge and we’re continuously developing new features, feature improvements, and capability improvements quickly.</p>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>BrandVerge's System Capabilities</h2>
                <p>BrandVerge’s platform allows brands to search, discover, and activate interesting opportunities in minutes, rather than working through the outdated &amp; manual RFP process.</p>
                <ul class="brandVergeSysCap">
                    <li>
                        <div className="innerCol">
                            <p className="title">USABILITY</p>
                            <p>The application is built with a very simple yet intuitive UI, which helps end-users to use the system without help from a technical person.</p>
                        </div>
                    </li>
                    <li>
                        <div className="innerCol">
                        <p className="title">SCALABILITY</p>
                            <p>The application ishosted on the AWS Cloud and configured to scale out based on load.</p>
                        </div>
                    </li>
                    <li>
                        <div className="innerCol">
                            <p className="title">Availability</p>
                            <p>New System Featurescan be deployed with a minimum downtime. The average response time from the application is less than 1 second.</p>
                        </div>
                    </li>
                    <li>
                        <div className="innerCol">
                            <p className="title">Extensibility</p>
                            <p>With Launchdarkly, the availability of deployed features can be limited to specific users. Using Google Analytics, a system admin can visualize user actions, the most-visited modules, and their response times. Code optimization and performance improvements can thus bemade accordingly.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>

    </div>
)
export default Brandverge

