import React from "react"
// import { Link } from "gatsby"

const InSkinMediaAdPlatform = () => (
    <div>
        <div className="projDtlsHeadImgWrap">
            <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616572052/digitalavenues.com/static/inSkinMockupFord.png" alt="" />
        </div>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>The InSkin Media Platform</h2>
                <p>Since 2010, the InSkin Ad Platform had been running on its own proprietary ad server, supporting the business in managing advertising campaigns and delivering them to appropriate target audiences across the world. However, overtime, the dedicated hosting server had some challenges:</p>
                <div className="innovationBlk projDtlsInvolvement">
                    <ul className="listStyle2">
                        <li><strong>Sub-optimal utilization of resources:</strong> Various systems were being run on a fixed set of servers. Subsequently, the same amount of computing resources were being used, irrespective of the load on the system.</li>
                        <li><strong>Scaling was difficult and time consuming:</strong> Adding computing resources during peak times and anticipated spikes, or scaling down resources during periods of low load was time consuming, as one had to go through the entire cycle of modifying the contract to provisioning servers before configuration and including it in the mix.</li>
                        <li><strong>Pricing:</strong> It became incredibly cost ineffective to maintain a dedicated hosting environment and provide dedicated support from the hosting company, and the cost was unjustified.</li>
                    </ul>
                </div>
                <p></p>
                <p>Due to these challenges, the best solution is to move the platform to a cloud-based infrastructure in order to have more choice and flexibility in its infrastructure management and costs.</p>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>Our Involvement</h2>

                <p>
                Our team provided multiple experiments and thorough verification and validation in order to implement the new cloud infrastructure. 
                </p>
                <div class="innovationBlk projDtlsInvolvement">
                    <ul>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Feasibility Study</div>
                            <div class="contRight">
                                <p>Our first step was conducting a feasibility study of various cloud providers and technologies available to us, then to build some Proof of Concepts (POCs) to validate our objectives. After reviewing the options with the client, we decided on using the Google Cloud Platform (GCP).</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Hosting Architecture Redesign</div>
                            <div class="contRight">
                                <p>
                                The next challenge was to design the hosting architecture on GCP. To maximize the effectiveness and value of the migration, we leveraged the various new services and power of GCP to create a more robust, scalable, and light-weight system that includes: 
                                </p>
                                <ul className="listStyle2">
                                    <li>Internal and external load balancers for auto-scaling</li>
                                    <li>Managed services like Redis, FileStore, StackDriver and Pub/Sub channels</li>
                                    <li>Distributed resources in multiple availability zones to increase reliability</li>
                                    <li>Image-based deployments</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Code Refactoring</div>
                            <div class="contRight">
                                <p>The team restructured the coding to be compatible with ASP.NET Core from classic ASP.NET. Data structure and methods were also re-written to improve the efficiency of performance and maintenance:</p>
                                <ul className="listStyle2">
                                    <li>Ported code from legacy ASP.NET to <strong>ASP.NET Core</strong> for the ad server</li>
                                    <li><strong>Linux OS</strong> instead of Windows was used to operate the ad server</li>
                                    <li>To log errors and warnings, we moved away from file-based storage to <strong>Stackdriver logging</strong></li>
                                    <li>To record and store ad server metrics, we moved away from file based storage to <strong>Pub/Sub channels</strong>, which directly pushed data to the client’s BigQuery storage</li>
                                    <li>We upgraded the SQL Server version to <strong>SQL Server 2016</strong> and used <strong>S2D</strong> for building the failover cluster</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Execute Migration</div>
                            <div class="contRight">
                                <p>After setting up the infrastructure in GCP, the code and database were migrated to the new platform on new sub-domains. Without incurring any downtime on the ad serving platform, the team employed certain temporary data sinks to store the transactional data while moving the main SQL Server databases to the new platform. Once the databases had been moved, calls to the ad server were redirected to the new domain. This helped ensure a seamless, zero downtime migration of the infrastructure.</p>
                            </div>
                        </li>
                        <li>
                            <div class="contLeft prodDtlsContLeft">Regression Testing</div>
                            <div class="contRight">
                                <p>Before moving the production system to the new platform, our quality team went through every single feature and functionality to ensure that the migration does not break any existing features. The team also engaged the ad server on GCP with all combinations of requests, and simulated various targeting methods to ensure that ad serving happened as expected. We also compared the output with the existing system to ensure like-for-like results.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="projDtlsSecContainer projDtlsContainer innerSection">
            <div className="wrapper">
                <h2>InSkin’s System Results</h2>
                <p>With the new cloud infrastructure, InSkin’s platform is now a fully scalable and load-balanced architecture where resources are being utilized based on the current load on the system. To further bring down costs and management overheads, the medium-term plan is to migrate the underlying SQL Server database to GCP’s managed PgSQL Service, where high availability and backups are available out of the box and the cost of the service is 60-70% lower than an equivalent SQL Server setup.</p>
                <div className="quickDesc texCenter">
                    <ul className="col3">
                        <li>
                            <div className="secCount">60<span>%</span></div>
                            <div>HOSTING<br />REDUCTION</div>
                            <p><strong>Hosting costs were reduced by 60%</strong> primarily due to using Linux OS for ad server instances and flexible number of instances being used as per need for all other services. The base minimum configuration for all services were also downgraded from the existing legacy hosting.</p>
                        </li>
                        <li>
                            <div className="secCount">30<span>%</span></div>
                            <div>Ad Requests<br />Ncreased</div>
                            <p><strong>Ad requests per second increased by 30%</strong> after deployment on GCP, even as it used less computing resources compared to dedicated hosting.</p>
                        </li>
                        <li>
                            <div className="secCount">∞</div>
                            <div className="minH40">INFINITE</div>
                            <p>The infrastructure <strong>auto-scales based on load</strong>, and is based on micro-services architecture without a single point of failure. The resources are distributed in multiple availability zones to mitigate the risk of physical data centre failures.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

    </div>
    )
export default InSkinMediaAdPlatform

